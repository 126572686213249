<template>
  <div id="policeCheckSubmitedMessage">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7 col-md-7 col-12">
          <div class="login-page bg-f5faff">
            <div class="card bg-white p-5 text-center">
              <div class="card-header text-muted border-bottom-0">
                <h4>Successful Submission</h4>
                <hr />
              </div>
              <div class="card-body text-center">
                <div class="text-center">
                  <p class="fw-600">
                    Thank you. Your
                    <span class="info-box-text fw-700 font-italic text-info"
                      >{{ product.product_category }}
                      <span
                        v-if="product.product_category != product.name"
                        class="fs-700 fw-700 font-italic"
                        >({{ product.name }})</span
                      ></span
                    >
                    has been submitted for processing.
                  </p>
                  <p class="text-muted">
                    Thank you for completing your National Police Check with CV
                    Screen.
                  </p>
                  <p class="fw-600">Next Steps</p>
                  <p class="">
                    Please check your email, including junk mail, to receive
                    notifications about the progress of your police check. CV
                    Screen may contact you to provide more information to
                    finalise your application.
                  </p>
                  <p class="">
                    We will email you once your police check results are
                    available. Please login to your secure dashboard to view
                    your police check certificate.
                  </p>
                  <p class="">
                    CV Screen, Police Agencies and the ACIC work together to
                    deliver most National Police Checks within 24-hours.
                  </p>
                  <p class="fw-600">Need Help?</p>
                  <p class="">
                    Please visit our
                    <a href="https://cvscreen.com.au/faqs" target="_blank"
                      >FAQ </a
                    >page at www.cvscreen.com.au/faqs
                  </p>
                  <p class="">
                    If you cannot find an answer to your query, please email
                    contact@cvscreen.com.au
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as encodeDecodeService from "../../services/encodeDecode.service";
export default {
  data() {
    return {
      product: {},
      productId: encodeDecodeService.cyDecrypt(this.$route.params.productId),
    };
  },
  computed: {
    ...mapGetters(["getProductList"]),
  },
  methods: {
    ...mapActions(["fetchProductList"]),

    fnGetProduct: function (productId) {
      const obj = this.getProductList;

      if (productId == "") {
        this.$router.push({ name: "dashboardIU" });
      }

      this.product = obj.find((e) => e.id == productId);

      return this.product;
    },
  },
  created() {
    this.fetchProductList();
  },
  watch: {
    getProductList: function (newVal) {
      this.fnGetProduct(this.productId);
    },
  },
};
</script>